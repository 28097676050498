import Vue from 'vue'
import Vuex from 'vuex'

/* Admin:Core */
import smtpConfig from '@/store/Admin/Core/smtpconfig'
import users from '@/store/Admin/Core/users'
import userTypes from '@/store/Admin/Core/user_types'
import abilities from '@/store/Admin/Core/abilities'
import verticalMenu from './vertical-menu'
import appConfig from './app-config'
import app from './app'
/* Admin:Defines */
import countries from './Admin/Defines/countries'
import cities from './Admin/Defines/cities'
import customerTypes from './Admin/Defines/customer_types'
import meets from './Admin/Defines/meets'
import sectors from './Admin/Defines/sectors'
import priorities from './Admin/Defines/priorities'
import supportStatuses from './Admin/Defines/support_statuses'
import taskStatuses from './Admin/Defines/task_statuses'
import taskTypes from './Admin/Defines/task_types'
/* Admin:App */
import customers from './Admin/App/customers'
import customerAddresses from './Admin/App/customer_addresses'
import customerUsers from './Admin/App/customer_users'
import projects from './Admin/App/projects'
import supports from './Admin/App/supports'
import supportLines from './Admin/App/support_lines'
import supportFiles from './Admin/App/support_files'
import dashboard from './Admin/App/dashboard'
import tasks from './Admin/App/tasks'
import taskFiles from './Admin/App/task_files'
import taskLines from './Admin/App/task_lines'
/* Admin:Reports */
import reportCustomers from './Admin/Reports/customers'
/* Client:App */
import clientPriorities from './Client/Defines/priorities'
import clientSupportStatuses from './Client/Defines/support_statuses'
import clientSupports from './Client/App/supports'
import clientSupportLines from './Client/App/support_lines'
import clientSupportFiles from './Client/App/support_files'
import clientProjects from './Client/App/projects'
import clientDashboard from './Client/App/dashboard'
import tokenAuth from './Client/App/tokenAuth'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    tokenAuth,
    smtpConfig,
    users,
    userTypes,
    abilities,
    countries,
    cities,
    customerTypes,
    meets,
    sectors,
    customers,
    customerAddresses,
    customerUsers,
    projects,
    supports,
    supportLines,
    supportFiles,
    dashboard,
    tasks,
    taskFiles,
    taskLines,
    priorities,
    supportStatuses,
    taskStatuses,
    taskTypes,
    reportCustomers,
    clientPriorities,
    clientSupportStatuses,
    clientSupports,
    clientSupportLines,
    clientSupportFiles,
    clientProjects,
    clientDashboard,
  },
  strict: process.env.DEV,
})
