import axiosIns from '@/libs/axios'
import moment from 'moment'

const ROOT_URL = '/Admin/reports/customers'
export default {
  namespaced: true,
  state: {
    dataList: [],
    filterParams: {
      sdate: moment().startOf('month').format('YYYY-MM-DD'),
      edate: moment().endOf('month').format('YYYY-MM-DD'),
    },
  },
  getters: {
    dataList(state) {
      return state.dataList
    },
    filterParams(state) {
      return state.filterParams
    },
  },
  mutations: {
    SET_DATA_LIST(state, data) {
      state.dataList = data
    },
  },
  actions: {
    getDataList({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post(ROOT_URL, data)
        .then(response => {
          commit('SET_DATA_LIST', response.data.data)
        })
        .catch(error => console.error(error))
    },
  },
}
